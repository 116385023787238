<template>
  <div id="Nav" :class="{ 'nav-bgc': isIndex, 'nav-login-bgc': loginPage }">
    <div class="header-box">
      <div class="nav-left">
        <router-link tag="a" to="/">
          <img class="logo" src="@/assets/image/nav/logo.png" alt="" />
        </router-link>

        <div v-if="isIndex" class="title text">
          <span class="title-l">用 知 识 为 爱 赋 能</span>
          <!-- <span> | </span>
          <span class="title-r">Empowered by knowledge</span> -->
        </div>

        <div v-else class="menu">
          <div
            class="menu-list"
            v-for="(item, index) in menuList"
            :key="index"
            :class="{ 'menu-bgc': menuIndex == index }"
            @click="onClick(item, index)"
          >
            <div class="menu-top">{{ item.ch }}</div>
            <!-- <div class="menu-bot">{{ item.en }}</div> -->
          </div>
        </div>
      </div>
      <div class="nav-right text">
        <div class="other">
          <a-popover placement="bottom" overlayClassName='overlay' :content="null"
            :getPopupContainer="triggerNode => {
              return triggerNode.parentNode
            }">
            <template slot="title">
              <img class="content-img" src="@/assets/image/nav/20220619-211727.png" alt="" />
            </template>
            <div class="downLoad">下载App</div>
          </a-popover>
          <a target="_blank" href="https://org.hxclass.cn/">
            <!-- <router-link to="/stop/servicesStop"> -->
            <div class="center">
              <img class="other-l" src="@/assets/image/nav/icon.png" alt="" />
              <span class="other-r">机构端</span>
            </div>
            <!-- </router-link> -->
          </a>
        </div>

        <div v-if="!isLogin" class="sign">
          <span class="sign-l" @click="toLogin(2)">登录</span>
          <span> | </span>
          <span class="sign-r" @click="toLogin(1)">注册</span>
        </div>

        <div v-if="isLogin" class="sign">
          <router-link tag="a" to="/personalCenter/DevLayout">
            <img
              class="person-l"
              :src="
                $store.state.userInfo.photo
                  ? $store.state.userInfo.photo
                  : GLOBAL.defaultUserImg
              "
              alt=""
            />
            <span class="person-r">{{
              $store.state.userInfo.userName
                ? $store.state.userInfo.userName
                : $store.state.userInfo.mobile
            }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: ["isIndex", "isLogin"],
  // 数据对象
  data() {
    return {
      loginPage: false, //是否为登录页面
      menuIndex: 0,
      menuList: [
        {
          id: 1,
          ch: "首页",
          en: "Home",
          path: "/",
          isClick: false,
        },
        {
          id: 2,
          ch: "书课专区",
          en: "Store",
          path: "/knowledgeMall/shopHome",
          isClick: false,
        },
        {
          id: 5,
          ch: "科普公益",
          en: "publicBenefit",
          path: "/publicBenefit",
          isClick: false,
        },
        {
          id: 3,
          ch: "学习中心",
          en: "Study",
          path: "/study",
          isClick: false,
        },
        {
          id: 4,
          ch: "认证查询",
          en: "Query",
          path: "/Query",
          isClick: false,
        },
      ],
      userType: 0,
    };
  },
  // 事件处理器
  methods: {
    onClick(x, i) {
      this.$router.push(x.path);
      this.menuIndex = i;

      // 注销是因为，重复点击导航时，hover样式会掉
      // x.isClick = !x.isClick
      // this.menuList.forEach(item=>{
      //   if(item.id != x.id){
      //     item.isClick = false
      //   }
      // })
    },
    // 登录、注册
    toLogin(type) {
      // // console.log(this.$route.path, this.$route.path.indexOf('/login/loginIndex'))
      // if (this.$route.path.indexOf('/login/loginIndex') == -1) {
      this.$store.commit("updateUserInfo", {
        loginState: 1, //1页面 2弹框
      });
      this.$router.push({
        path: "/login/loginIndex",
        query: { type: type },
      });
      // }
    },
    // 个人中心
    goCenter() {
      this.$router.push({
        path: "/personalCenter/MyCourse",
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.userType = this.$store.state.userInfo.userType;
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route() {
      // 监听是页面是首页和商城时，增加菜单选中效果
      if (this.$route.path == "/") {
        this.menuIndex = 0;
      } else if (this.$route.path == "/knowledgeMall/shopHome") {
        this.menuIndex = 1;
      } else if (this.$route.path == "/study/study") {
        this.menuIndex = 3;
      } else if (this.$route.path == "/Query/Query") {
        this.menuIndex = 4;
      } else if (this.$route.path == "/publicBenefit") {
        this.menuIndex = 2;
      } else {
        this.menuIndex = null;
      }
      // 登录页面改变样式
      if (this.$route.path.indexOf("/login/loginIndex") > -1) {
        this.loginPage = true;
      }else{
        this.loginPage = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#Nav {
  background-color: #FFFFFF;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
}

.nav-bgc {
  background-color: #FFFFFF !important;
}
.nav-login-bgc {
  // background-color: rgba(10, 117, 146, 1) !important;
  // *{
  //   color: #ffffff !important;
  // }
}

.header-box {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 1200px;
  height: 100%;
  align-items: center;
  .text {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #ffffff;
    line-height: 60px;
    letter-spacing: 4px;
  }

  .nav-left {
    display: flex;
    .logo {
      margin-right: 78px;
      // width: 130px;
      height: 60px;
    }
    .title {
      color: rgba(255, 255, 255, 0.8);
      .title-r {
        letter-spacing: 2px;
      }
    }
    .menu {
      display: flex;
      cursor: pointer;
      .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 60px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        .menu-top {
          // margin-bottom: 6px;
          font-weight: 400;
        }
        .menu-bot {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .menu-bgc, .menu-list:hover {
        color: #111111;
        position: relative;
        .menu-top {
          font-weight: bold;
        }
      }
      .menu-bgc::after, .menu-list:hover::after{
        content: '';
        width: 24px;
        height: 3px;
        background: #15B7DD;
        border-radius: 2px;
        position: absolute;
        bottom: 14px;
      }
    }
  }

  .nav-right {
    display: flex;
    letter-spacing: 1px;
    .sign {
      display: flex;
      align-items: center;
      .person-l {
        margin-right: 12px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      img {
        cursor: pointer;
      }
      span {
        color: #333333;
        cursor: pointer;
      }
    }
    .other {
      display: flex;
      align-items: center;
      margin-right: 60px;
      .downLoad{
        cursor: pointer;
        margin-right: 33px;
        font-family: PingFangMedium;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        position: relative;
      }
      .downLoad::after{
        content: '|';
        position: absolute;
        right: -17px;
        color: rgba(0,0,0,0.05);;
      }
      .center {
        display: flex;
        align-items: center;
      }
      .other-l {
        margin-right: 4px;
        width: 17px;
        height: 17px;
      }
      .other-r {
        font-family: PingFangMedium;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}

.overlay{
  top: 40px !important;
  img{
    width: 150PX;
  }
}
/deep/.ant-popover-title{
  border: 0 !important;
}
/deep/.ant-popover-inner-content{
  padding: 0 !important;
}
// 小屏分辨率内容区呈现1200px布局
// @media screen and (max-width: 16000px) {
//   .header-box {
//     .nav-left {
//       .menu {
//         .menu-list {
//           font-size: 14px;
//         }
//       }
//     }
//     .text{
//       font-size: 14px;
//     }
//   }
// }
</style>
